@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');

@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

@font-face {
    font-family: 'Blasto Distort';
    src: url('../../../public/fonts/Blasto_Distort.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Grunge Font';
    src: url('../../../public/fonts/GrungeFont-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

$px-font1: 'Blasto Distort', sans-serif;
$px-font2: 'Grunge Font', sans-serif;

// Theme Variable

$px-theme: #3749E9;
$px-theme-rgb: 7, 136, 255;
$px-white: #fff;
$px-black: #040c16;

$px-text: #000000;
$px-heading: #fff;

// Breakpoints
$px-media-xsm: 567px !default;
$px-media-sm: 767px !default;
$px-media-md: 991px !default;
$px-media-lg: 1200px !default;