img {
    height: auto;
}

.landing-product-list {
    background-color: #FFCD00;

    .landing-prodcard {
        position: relative;
        z-index: 1;
        margin: 20px;

        .prod-titlecard {
            background-color: #A100FF;
            padding: 40px 10px;
            min-height: 350px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            position: relative;
            bottom: 60px;
            z-index: -1;

            h3 {
                font-family: $px-font1;
                font-size: 16px;
            }

            .btn-land {
                background-color: #FFCD00;
                color: #A100FF;
                font-family: $px-font1;
                font-size: 12px;
                margin: 10px 0;
                
            }

            .prod-shopat {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                gap: 10px;
                align-items: center;

                img {
                    width: 50%
                }

                .more-image {
                    img {
                        width: 52px;
                    }
                }

                a {
                    text-decoration: none;
                    font-family: 'Sora', sans-serif;
                    color: #FFCD00;
                    font-weight: 600;
                }
            }
        }
    }
}


.swiggy-image {
    display: block;
    margin-bottom: 10px;
    width: 100%;
}

.close-modal-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: red;
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 18px;
    cursor: pointer;
    z-index: 1002;
    font-weight: bold;
}

.modal-content {
    .landing-product-list {
        background-color: transparent !important;
    }

    .prod-titlecard {
        min-height: 390px !important;
        padding: 40px 10px !important;

        .prod-shopat {
            flex-direction: column !important;
            gap: 20px !important;

            img {
                width: 100px !important;
            }
        }
    }
}

.product-range {
    background-color: #FFCD00;
    margin-top: 360px;
    margin-bottom: 100px;
    height: 730px;

    .range-card {
        position: relative;
        bottom: 300px;
    }

    .btn-land {
        background-color: #A100FF;
        color: #FFCD00;
        font-family: $px-font1;
        font-size: 18px;
        // margin: 10px 0;
        padding: 10px;
        border-radius: 10px;
    }

    .store-fronts {
        display: flex;
        padding: 10px;
        justify-content: center;

        img {
            width: 95px;
            height: 95px;
        }
    }
}

.copyright-land {
    background-color: white;
}


/*Responsive*/

@media (max-width: 480px) {
    .landing-product-list {
        .landing-prodcard {
            margin: 0;

            .prod-titlecard {
                min-height: 275px;
                padding: 20px 10px;


                h3 {
                    font-size: 12px;
                }

                .btn-land {
                    font-size: 9px;
                }
                
                .prod-shopat {
                    img {
                        width: 75%;
                    }
                }
            }
        }
    }

    .product-range {
        height: 430px;
        margin-top: 110px;
        margin-bottom: 55px;

        .range-card {
            bottom: 85px;

            .btn-land {
                font-size: 10px;
            }
        }

        .store-fronts {

            img {
                width: 65px;
                height: 65px;
            }
        }
    }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
    .landing-product-list {
        .landing-prodcard {
            margin: 0;

            .prod-titlecard {
                h3 {
                    font-size: 12px;
                }

            }
        }
    }

    .product-range {
        height: 300px;
        margin-top: 105px;
        margin-bottom: 55px;

        .range-card {
            bottom: 75px;

            .btn-land {
                font-size: 10px;
            }
        }
    }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
    .landing-product-list {
        .landing-prodcard {
            margin: 0;

            .prod-titlecard {

                min-height: 315px;

                h3 {
                    font-size: 12px;
                }

                .btn-land {
                    font-size: 10px;
                }

                .prod-shopat {

                    .more-image {
                        img {
                            width: 32px;
                        }
                    }
                }
            }
        }
    }

    .product-range {
        height: 450px;
        margin-top: 250px;
        margin-bottom: 75px;

        .range-card {
            bottom: 200px;

            .btn-land {
                font-size: 10px;
            }
        }
    }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
    
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
  
}